import { useEffect } from "react";
import { TypeBridge } from "../bridge";

export function useAdaptiveHeight({ bridge }: { bridge: TypeBridge }) {
  useEffect(() => {
    bridge.styleCurrentPlugin({
      plugin: {
        height: window.document.body.scrollHeight,
        disableClose: false,
      },
    });
  }, [bridge]);
}
