import "src/styles/f.css.ts.vanilla.css!=!../../.yarn/__virtual__/@vanilla-extract-webpack-plugin-virtual-d2f2bd50aa/0/cache/@vanilla-extract-webpack-plugin-npm-2.1.11-9e0152fd4b-7104ef7a88.zip/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/styles/f.css.ts.vanilla.css\",\"source\":\"LnN5NTZmZjAgewogIGZvbnQtc2l6ZTogMC44NzVyZW07CiAgZm9udC13ZWlnaHQ6IDQwMDsKICBsaW5lLWhlaWdodDogMS4yNXJlbTsKICBsZXR0ZXItc3BhY2luZzogMDsKICB0ZXh0LWFsaWduOiBsZWZ0Owp9Ci5zeTU2ZmYxIHsKICBmb250LXNpemU6IDFyZW07CiAgZm9udC13ZWlnaHQ6IDQwMDsKICBsaW5lLWhlaWdodDogMS4zNzVyZW07CiAgbGV0dGVyLXNwYWNpbmc6IDA7CiAgdGV4dC1hbGlnbjogbGVmdDsKfQouc3k1NmZmMiB7CiAgZm9udC1zaXplOiAxLjEyNXJlbTsKICBmb250LXdlaWdodDogNzAwOwogIGxpbmUtaGVpZ2h0OiAxLjVyZW07CiAgbGV0dGVyLXNwYWNpbmc6IDA7CiAgdGV4dC1hbGlnbjogbGVmdDsKfQouc3k1NmZmMyB7CiAgbWFyZ2luLWJvdHRvbTogMXJlbTsKfQouc3k1NmZmNCB7CiAgZGlzcGxheTogZmxleDsKfQouc3k1NmZmNSB7CiAgYWxpZ24taXRlbXM6IGNlbnRlcjsKfQouc3k1NmZmNiB7CiAgY3Vyc29yOiBwb2ludGVyOwogIC13ZWJraXQtdGFwLWhpZ2hsaWdodC1jb2xvcjogdHJhbnNwYXJlbnQ7Cn0KLnN5NTZmZjcgewogIGFwcGVhcmFuY2U6IG5vbmU7CiAgYm9yZGVyOiAwOwogIHBhZGRpbmc6IDA7CiAgYmFja2dyb3VuZDogbm9uZTsKfQouc3k1NmZmOCB7CiAgd2lkdGg6IDEwMCU7Cn0=\"}!../../.yarn/__virtual__/@vanilla-extract-webpack-plugin-virtual-d2f2bd50aa/0/cache/@vanilla-extract-webpack-plugin-npm-2.1.11-9e0152fd4b-7104ef7a88.zip/node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
import "src/components/Main.css.ts.vanilla.css!=!../../.yarn/__virtual__/@vanilla-extract-webpack-plugin-virtual-d2f2bd50aa/0/cache/@vanilla-extract-webpack-plugin-npm-2.1.11-9e0152fd4b-7104ef7a88.zip/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"src/components/Main.css.ts.vanilla.css\",\"source\":\"Ll83eDN6ZDQwIHsKICBwYWRkaW5nOiAycmVtIDFyZW0gMS41cmVtOwp9Ci5fN3gzemQ0MiB7CiAgbWFyZ2luLWJvdHRvbTogLjI1cmVtOwogIGNvbG9yOiB2YXIoLS1zZWVkLXNjYWxlLWNvbG9yLWdyYXktOTAwKTsKfQouXzd4M3pkNDMgewogIGNvbG9yOiB2YXIoLS1zZWVkLXNjYWxlLWNvbG9yLWdyYXktOTAwKTsKfQouXzd4M3pkNDQgewogIGhlaWdodDogMXB4OwogIGJhY2tncm91bmQtY29sb3I6IHZhcigtLXNlZWQtc2VtYW50aWMtY29sb3ItZGl2aWRlci0xKTsKfQouXzd4M3pkNDYgewogIHBhZGRpbmc6IDAuNzVyZW0gMDsKfQouXzd4M3pkNDcgewogIGNvbG9yOiB2YXIoLS1zZWVkLXNjYWxlLWNvbG9yLWdyYXktOTAwKTsKICBtYXJnaW4tcmlnaHQ6IDAuNzVyZW07Cn0KLl83eDN6ZDQ4IHsKICBjb2xvcjogdmFyKC0tc2VlZC1zY2FsZS1jb2xvci1ncmF5LTkwMCk7Cn0=\"}!../../.yarn/__virtual__/@vanilla-extract-webpack-plugin-virtual-d2f2bd50aa/0/cache/@vanilla-extract-webpack-plugin-npm-2.1.11-9e0152fd4b-7104ef7a88.zip/node_modules/@vanilla-extract/webpack-plugin/extracted/dist/vanilla-extract-webpack-plugin-extracted.cjs.js"
export var container = '_7x3zd40';
export var divider = '_7x3zd44 sy56ff3';
export var menu = '_7x3zd45';
export var menuItem = '_7x3zd46 sy56ff5 sy56ff4 sy56ff6 sy56ff7 sy56ff8';
export var menuItemIcon = '_7x3zd47 sy56ff5 sy56ff4';
export var menuItemLabel = '_7x3zd48 sy56ff1';
export var reason = 'sy56ff3';
export var reasonTitle = '_7x3zd42 sy56ff0';
export var reasonWhy = '_7x3zd43 sy56ff2';