export async function deleteShortcutHistory({
  xAuthToken,
}: {
  xAuthToken: string;
}) {
  const REMOVE_HISTORY_API_ENDPOINT =
    process.env.STAGE === "production"
      ? "https://shortcut.kr.karrotmarket.com/shortcut/v1/users/me/services/visited"
      : "https://shortcut.alpha.kr.karrotmarket.com/shortcut/v1/users/me/services/visited";

  const headers = new Headers();
  headers.set("X-Auth-Token", xAuthToken);

  const response = await fetch(REMOVE_HISTORY_API_ENDPOINT, {
    method: "delete",
    headers,
  }).then((r) => r.json());

  return response;
}
