import React, { useEffect } from "react";

import { bridge } from "../bridge";
import { useAdaptiveHeight } from "../lib";
import { deleteShortcutHistory } from "../remote-api";
import IconRemoveCircle from "./IconRemoveCircle";
import * as css from "./Main.css";

const Main: React.FC = () => {
  useAdaptiveHeight({ bridge });

  useEffect(() => {
    bridge.logEvent({
      analytics: {
        target: "FIREBASE",
        name: "shortcut_event",
        params: JSON.stringify({
          name: "show_shortcut_overflow",
        }),
      },
    });
  }, []);

  const onRemoveHistoryClick = async () => {
    const {
      info: { user },
    } = await bridge.getUserInfo({});

    await deleteShortcutHistory({
      xAuthToken: user.authToken,
    });

    bridge.emitToStream({
      stream: {
        eventName: "REFRESH_HOME_FEED",
      },
    });

    bridge.logEvent({
      analytics: {
        target: "FIREBASE",
        name: "shortcut_event",
        params: JSON.stringify({
          name: "click_remove_history",
        }),
      },
    });

    bridge.closePlugin({});
  };

  return (
    <div className={css.container}>
      <div className={css.reason}>
        <div className={css.reasonTitle}>이 영역이 표시되는 이유는 아래와 같아요</div>
        <div className={css.reasonWhy}>
          방금 사용한 서비스를 찾기 쉽게 보여줘요
        </div>
      </div>
      <div className={css.divider} />
      <div className={css.menu}>
        <button
          className={css.menuItem}
          type="button"
          onClick={onRemoveHistoryClick}
        >
          <IconRemoveCircle className={css.menuItemIcon} />
          <div className={css.menuItemLabel}>방문기록 지우기</div>
        </button>
      </div>
    </div>
  );
};

export default Main;
